import { useCallback, useEffect, useState } from "react";

export function useLocalStorage(
  key: string,
  initialValue: string
): [string, (newValue: string) => void] {
  const [value, _setValue] = useState(
    localStorage.getItem(key) || initialValue
  );
  const setValue = useCallback(
    (newValue: string) => {
      localStorage.setItem(key, newValue);
      _setValue(newValue);
    },
    [key]
  );
  useEffect(() => {
    _setValue(localStorage.getItem(key) || initialValue);
  }, [key, initialValue]);
  return [value, setValue];
}
