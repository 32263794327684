import FeezbackAggregate from "./lib/feezback-aggregate/feezback-aggregate";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { getFeezbackAggregateConfig } from "./feezbackAggregateConfig";
import { useLocalStorage } from "./hooks";
import ThemeEditorWithBaseTheme from "./ThemeEditor";

// Use memo so iframe won't be re-rendered
const FeezbackIframe = memo(
  ({
    updateIframeRef,
  }: {
    updateIframeRef: (iframe: HTMLIFrameElement) => void;
  }) => (
    <iframe
      ref={updateIframeRef}
      id="feezback-aggregate-iframe"
      title="FeezbackAggregate"
    ></iframe>
  ),
  () => true
);

const isMobile = (() => {
  const userAgent = navigator.userAgent || navigator.vendor;
  return (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      userAgent.substr(0, 4)
    )
  );
})();

const constantUserId = (window as any).constantUserId;

function App() {
  const iframeRef = useRef<HTMLIFrameElement>();
  const [onboardingConfigString, setOnboardingConfig] = useLocalStorage(
    "onboardingConfigString",
    JSON.stringify(
      {
        flow: {
          id: "default",
          dataBaskets: ["ACCOUNTS", "BALANCES", "TRANSACTIONS"],
          mandatoryDataBaskets: ["BALANCES"],
          timePeriods: [
            "ONE_DAY",
            "THREE_MONTHS",
            "SIX_MONTHS",
            "TWELVE_MONTHS",
          ],
          defaultTimePeriod: "TWELVE_MONTHS",
          accountTypes: ["CACC", "CARD"],
        },
        userIdentifier: {
          type: FeezbackAggregate.UserIdentifierType.ID,
          value: "000000018",
          editable: true,
        },
      },
      null,
      2
    )
  );
  const [userId, setUserId] = useLocalStorage("userId", "12345");
  const [showThemeEditor, setShowThemeEditor] = useState(
    window.location.hash === "#themeEditor"
  );
  const userIdRef = useRef(constantUserId || userId);
  useEffect(() => {
    if (!constantUserId) {
      userIdRef.current = userId;
    }
  }, [userId]);

  const [resultString, setResultString] = useState("");

  const feezbackAggregateResultHandler = useCallback(
    (result: {
      processType: FeezbackAggregate.ProcessType;
      result: FeezbackAggregate.Result;
    }) => {
      if (
        result.processType === FeezbackAggregate.ProcessType.JOIN &&
        result.result === FeezbackAggregate.Result.SUCCESS
      ) {
        window.location.href = "./success.html";
        return;
      }
      setResultString(
        `Process ${result.processType}'s result is ${result.result}`
      );
    },
    []
  );

  const feezbackAggregateWasInitialized = useRef(false);
  const initializeFeezbackAggregate = useCallback(() => {
    if (feezbackAggregateWasInitialized.current) {
      return;
    }
    feezbackAggregateWasInitialized.current = true;
    FeezbackAggregate.init(getFeezbackAggregateConfig(userIdRef));
    FeezbackAggregate.subscribe({
      // processType: FeezbackAggregate.ProcessType.JOIN,
      callback: feezbackAggregateResultHandler,
    });
  }, [feezbackAggregateResultHandler]);
  useEffect(initializeFeezbackAggregate, [initializeFeezbackAggregate]);

  const startAuth = () => {
    if (!iframeRef.current) return;

    const onboardingConfigObj = JSON.parse(onboardingConfigString);

    FeezbackAggregate.startJoinProcess({
      iframe: iframeRef.current,
      flow: onboardingConfigObj["flow"],
      userIdentifier: onboardingConfigObj["userIdentifier"],
    });
  };

  const updateIframeRef = (iframe: HTMLIFrameElement) => {
    iframeRef.current = iframe;
    const urlParams = new URLSearchParams(window.location.search);
    const state = urlParams.get("state");
    initializeFeezbackAggregate();
    if (state) {
      FeezbackAggregate.resume({
        state,
        iframe,
        code: urlParams.get("code"),
        error: urlParams.get("error"),
        processed: urlParams.get("processed"),
      });
    } else {
      startAuth();
    }
  };

  const startManagementProcess = () => {
    if (!iframeRef.current) return;
    FeezbackAggregate.startManagementProcess({ iframe: iframeRef.current });
  };
  const goBack = () => {
    if (!iframeRef.current) return;
    window.history.back();
  };

  return (
    <div className={"App" + (isMobile ? " app-mobile" : "")}>
      <div id="controllers-container">
        <button onClick={goBack}>&lt;</button>
        <button onClick={() => setShowThemeEditor((c) => !c)}>🎨</button>
        <input
          type="text"
          placeholder="User ID"
          id="user-id"
          readOnly={!!constantUserId}
          value={constantUserId || userId}
          onChange={(e) => {
            if (!constantUserId) {
              setUserId(e.target.value);
            }
          }}
        />
        <textarea
          placeholder="Config"
          value={onboardingConfigString}
          onChange={(e) => setOnboardingConfig(e.target.value)}
        />
        <button className="primary" onClick={startAuth}>
          {isMobile ? "🪄" : "Start Authentication Process"}
        </button>
        <button onClick={startManagementProcess}>
          {isMobile ? "⚙️" : "Manage"}
        </button>
      </div>
      <div id="main">
        {showThemeEditor && (
          <ThemeEditorWithBaseTheme
            iframeRef={iframeRef}
            userIdRef={userIdRef}
          />
        )}
        <FeezbackIframe updateIframeRef={updateIframeRef} />
      </div>
      <p>{resultString}</p>
    </div>
  );
}

export default App;
