import { MutableRefObject, RefObject, useEffect, useState } from "react";
import { getFeezbackAggregateConfig } from "./feezbackAggregateConfig";
import { useLocalStorage } from "./hooks";
import JSONEditorReact from "./JsonEditor";
import FeezbackAggregate from "./lib/feezback-aggregate/feezback-aggregate";

type ThemeEditorProps = {
  iframeRef: MutableRefObject<HTMLIFrameElement | undefined>;
  initialValue: string;
  reload: () => void;
};

const updateTheme = (
  theme: string,
  themeId: string,
  iframe: HTMLIFrameElement | undefined
) => {
  let obj: any;
  try {
    obj = JSON.parse(theme);
  } catch {
    alert("Invalid JSON");
    return;
  }

  const iframeWindow = iframe?.contentWindow;
  if (!iframeWindow) {
    return;
  }
  if (!(iframeWindow as any).feezbackAggregate) {
    return;
  }
  (iframeWindow as any).setTheme?.(obj, themeId);
};

const LOCAL_STORAGE_THEME_KEY = "theme";
const DEFAULT_THEME_ID = "base";

const ThemeEditor = ({ iframeRef, initialValue, reload }: ThemeEditorProps) => {
  const [mode, setMode] = useState("code");
  const [theme, setTheme] = useLocalStorage(
    LOCAL_STORAGE_THEME_KEY,
    initialValue
  );
  const [themeId, setThemeId] = useLocalStorage("themeId", DEFAULT_THEME_ID);
  useEffect(() => {
    setTheme(initialValue);
    setThemeId(DEFAULT_THEME_ID);
    updateTheme(initialValue, DEFAULT_THEME_ID, iframeRef.current);
  }, [initialValue, setTheme, setThemeId, iframeRef]);

  return (
    <>
      <div className="menu">
        <input
          type="text"
          placeholder="Theme ID"
          value={themeId}
          onChange={(e) => setThemeId(e.target.value)}
        />
        <button
          className="primary"
          onClick={() => updateTheme(theme, themeId, iframeRef.current)}
        >
          Update
        </button>
        <button onClick={reload}>Reset</button>
      </div>
      <JSONEditorReact
        text={theme}
        mode={mode}
        modes={["code", "tree"]}
        onModeChange={setMode}
        indentation={2}
        fontSize={10}
        onChangeText={(text: string) => setTheme(text)}
      />
    </>
  );
};

const ThemeEditorWithBaseTheme = ({
  userIdRef,
  ...props
}: { iframeRef: MutableRefObject<HTMLIFrameElement | undefined> } & {
  userIdRef: RefObject<string>;
}) => {
  const [initialValue, setInitialValue] = useState<string | null>(null);
  useEffect(() => {
    if (initialValue) {
      return () => {};
    }
    const localTheme = localStorage.getItem(LOCAL_STORAGE_THEME_KEY);
    if (localTheme) {
      setInitialValue(localTheme);
      return () => {};
    }
    let active = true;
    const config = getFeezbackAggregateConfig(userIdRef);
    config
      .fetch(
        FeezbackAggregate.HttpMethod.GET,
        config.staticFilesBaseUrl + "/theme.json",
        null
      )
      .then((res) => {
        if (active && "content" in res) {
          setInitialValue(res.content);
          localStorage.setItem(LOCAL_STORAGE_THEME_KEY, res.content);
        } else {
          throw Error();
        }
      })
      .catch((e) => {
        console.error(e);
        setInitialValue("{}");
        localStorage.setItem(LOCAL_STORAGE_THEME_KEY, "{}");
      });
    return () => {
      active = false;
    };
  }, [userIdRef, initialValue]);

  return (
    <div id="theme-editor" className={initialValue ? "" : "loading"}>
      {initialValue ? (
        <ThemeEditor
          {...props}
          reload={() => {
            localStorage.removeItem(LOCAL_STORAGE_THEME_KEY);
            setInitialValue(null);
          }}
          initialValue={initialValue}
        />
      ) : (
        "Loading theme..."
      )}
    </div>
  );
};

export default ThemeEditorWithBaseTheme;
