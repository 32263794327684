import { RefObject } from "react";
import FeezbackAggregate from "./lib/feezback-aggregate/feezback-aggregate";

const LOCALHOST = "http://localhost:8000";
export const baseUrls = (() => {
  if ((window as any).apiServerBaseUrl && (window as any).staticFilesBaseUrl) {
    const { apiServerBaseUrl, staticFilesBaseUrl } = window as any;
    return { apiServerBaseUrl, staticFilesBaseUrl };
  }
  switch (process.env.REACT_APP_SERVER) {
    case "AGG_PROD":
      return {
        apiServerBaseUrl: "https://agg-production.herokuapp.com/ui/",
        staticFilesBaseUrl:
          "https://agg-production.herokuapp.com/static/aggregate/",
      };
    case "AGG_STAG":
      return {
        apiServerBaseUrl: "https://dev.feez.link/sdk/user/v1/",
        staticFilesBaseUrl: "https://static.dev.feez.link/tenants/a/shared/",
      };
  }
  return {
    apiServerBaseUrl: LOCALHOST + "/user/v1/",
    staticFilesBaseUrl: LOCALHOST + '/static/example/tenants/a/shared',
  };
})();

export const getFetch =
  (userIdRef: RefObject<string>) =>
  async (
    method: FeezbackAggregate.HttpMethod,
    url: string,
    jsonString: string | null
  ) => {
    const headers: Record<string, string> = {
      "X-User-Id": userIdRef.current!,
    };

    if (
      [
        FeezbackAggregate.HttpMethod.POST,
        FeezbackAggregate.HttpMethod.PUT,
        FeezbackAggregate.HttpMethod.PATCH,
      ].indexOf(method) !== -1
    ) {
      headers["Content-Type"] = "application/json";
    }

    const response = await fetch(url, {
      method: method,
      headers,
      body: jsonString,
    });

    if (response.status < 200 || 300 <= response.status) {
      throw new Error();
    }

    const content = await response.text();
    return {
      type: response.headers.get("Content-Type") || "",
      content: content,
    } as FeezbackAggregate.FetchFunctionResult;
  };

export const getFeezbackAggregateConfig = (userIdRef: RefObject<string>) => ({
  ...baseUrls,
  fetch: getFetch(userIdRef),
  logger: (level: FeezbackAggregate.LogLevel, message: string) => {
    switch (level) {
      case FeezbackAggregate.LogLevel.DEBUG:
        console.debug("Aggregate: " + message);
        break;
      case FeezbackAggregate.LogLevel.INFO:
        console.log("Aggregate: " + message);
        break;
      case FeezbackAggregate.LogLevel.WARN:
        console.warn("Aggregate: " + message);
        break;
      case FeezbackAggregate.LogLevel.ERROR:
        console.error("Aggregate: " + message);
        break;
      case FeezbackAggregate.LogLevel.CRITICAL:
        console.log("*** CRITICAL ***");
        console.error("Aggregate: " + message);
        break;
    }
  },
});
